.navigation {
  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  padding: 0.5rem 0rem;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
}

.brand-name {
  text-decoration: none;
  color: rgb(5, 5, 5);
  font-size: 1.5rem;
  margin-left: 1rem;
}

.navigation-menu {
  margin-left: auto;
}

.navigation-menu ul {
  display: flex;
  padding: 0;
}

.navigation-menu li {
  list-style-type: none;
  margin: 0 1rem;
}

.navigation-menu li a {
  text-decoration: none;
  color: rgb(10, 2, 2);
  font-size: 2rem;
  display: block;
  width: 100%;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #283b8b;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}

.hamburger:hover {
  background-color: #2642af;
}

@media screen and (max-width: 550px) {
  .hamburger {
    display: block;
  }

  .navigation-menu ul {
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background-color: rgb(222, 222, 230);
    border-top: 1px solid black;
    display: none;
  }

  .navigation-menu li {
    text-align: center;
    margin: 0;
  }

  .navigation-menu li a {
    color: black;
    width: 100%;
    padding: 1.5rem 0;
  }

  .navigation-menu li:hover {
    background-color: #eee;
  }

  .navigation-menu.expanded ul {
    display: block;
  }
}
